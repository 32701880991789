<script lang="ts" setup>
import { usePageField } from '@voix/composables/usePageField'
import { computed, onMounted, ref } from 'vue'
import { usePageStore } from '@voix/store/pageStore'
import Container from './Container.vue'
import Hamburger from './Hamburger.vue'
import Accordion from './Accordion.vue'
import AccordionHeader from './AccordionHeader.vue'
import AccordionItem from './AccordionItem.vue'
import AccordionBody from './AccordionBody.vue'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

const props = defineProps({
  currentResort: {
    type: Object,
    default: () => {},
  },
  resorts: {
    type: Array,
    required: true,
  },
})
const pageField = usePageField()
const hideBookNow = pageField.get('hideBookNow', false)
const hideNavigation = pageField.get('hideNavigation', false)

const pageStore = usePageStore()
const currentPage = pageStore.currentPage

const menuIsOpen = ref(false)
const scrollY = ref(0)
const hamburgler = ref(null)
const windowIsScrolled = computed(() => scrollY.value > 0)
const bookingWidgetStore = useBookingWidgetStore()
const showBookingWidget = computed(() => bookingWidgetStore.showBookingModal)

const accentColor: { value: string } = inject('accentColor') as { value: string }

function toggleBookingModal() {
  bookingWidgetStore.toggleBookingModal('nav')
}
function onBookNowClick() {
  toggleBookingModal()
  if (hamburgler.value)
    hamburgler.value.toggle()
}

const menuItemsWithoutResorstsAndBrands = computed(() => {
  if (!props.currentResort?.menu)
    return []

  return props.currentResort.menu.menu_items.filter((item) => {
    return item.name !== 'Our Resorts'
      && item.name !== 'Our Brands'
      && item.name !== 'Nuestros Resorts'
      && item.name !== 'Nuestras Marcas'
  })
})

onMounted(() => {
  window.onscroll = () => {
    scrollY.value = window.scrollY
  }
})

const alphabetizedResorts = computed(() => {
  const resorts = JSON.parse(JSON.stringify(props.resorts))
  return resorts.sort((a, b) => {
    if (a.name < b.name)
      return -1
    if (a.name > b.name)
      return 1
    return 0
  })
})

const enLink = computed(() => {
  if (currentPage?.language_code === 'en')
    return { path: currentPage.path, language_code: currentPage.language_code }

  return currentPage?.localizations.find((localization: any) => localization.language_code === 'en')
})

const esLink = computed(() => {
  if (currentPage?.language_code === 'es')
    return { path: currentPage.path, language_code: currentPage.language_code }

  return currentPage?.localizations.find((localization: any) => localization.language_code === 'es')
})

const bookNowBgColor = computed(() => {
  if (windowIsScrolled.value)
    return accentColor.value

  return '#fff'
})
const bookNowTextColor = computed(() => {
  if (windowIsScrolled.value)
    return '#fff'

  return accentColor.value
})
</script>

<template>
  <nav
    v-if="!hideNavigation.value"
    :class="{
      'scrolled': windowIsScrolled,
      'menu-open': menuIsOpen,
    }"
  >
    <Container>
      <div id="nav-content" class="flex items-center justify-between">
        <div class="w-1/5 relative flex justify-start">
          <div class="bg-prussianBlue/25 blur-xl absolute inset-0" />
          <Hamburger ref="hamburgler" v-model="menuIsOpen" />
        </div>

        <div class="w-3/5 flex justify-center relative text-white">
          <div v-if="!windowIsScrolled" class="bg-prussianBlue/25 blur-xl absolute z-0 inset-0 " />
          <a href="/" class="relative z-10">
            <LogosPlaya id="logo" class="w-[90px] sm:w-[150px]" :fill="!menuIsOpen && windowIsScrolled" />
          </a>
        </div>

        <div class="w-1/5 flex items-center justify-end space-x-6 pr-2 md:pr-0">
          <ul
            v-if="currentPage && currentPage.localizations.length" class="flex items-center divide-x"
            :class="{
              'devide-white': menuIsOpen || !windowIsScrolled,
              'devide-black': !menuIsOpen && windowIsScrolled,
            }"
          >
            <li class="uppercase text-white px-2">
              <NuxtLink
                :key="enLink?.path"
                :to="enLink?.path"
                class="hover:underline"
                :class="{
                  'text-grey': !menuIsOpen && windowIsScrolled,
                }"
              >
                EN
              </NuxtLink>
            </li>
            <li class="uppercase text-white px-2">
              <NuxtLink
                :key="esLink?.path"
                :to="esLink?.path"
                class="hover:underline"
                :class="{
                  'text-grey': !menuIsOpen && windowIsScrolled,
                }"
              >
                ES
              </NuxtLink>
            </li>
          </ul>

          <button
            v-if="!hideBookNow.value"
            type="submit"
            class="hidden md:block px-3 lg:px-6 py-2 lg:py-3 uppercase text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed"
            :style="{
              backgroundColor: bookNowBgColor,
              color: bookNowTextColor,
            }"
            @click="toggleBookingModal"
          >
            {{ $t('book-now') }}
          </button>
        </div>
      </div>

      <div class="menu hidden">
        <!-- Hide on Playacares -->
        <Accordion v-if="currentResort?.id !== 29" class="max-w-[858px] mx-auto">
          <AccordionItem :index="0">
            <AccordionHeader show-plus-minus>
              {{ $t('our-resorts') }}
            </AccordionHeader>
            <AccordionBody>
              <div class="flex flex-wrap lg:grid grid-cols-3 gap-12 justify-center  text-center lg:text-left">
                <div class="mb-8">
                  <h4 class="text-sm font-bold tracking-widest mb-4 uppercase border-b border-white/50 pb-1.5">
                    Dominican Republic
                  </h4>
                  <ul>
                    <template
                      v-for="(resort, resortsKey) in alphabetizedResorts"
                    >
                      <template v-if="resort.country === 'Dominican Republic'">
                        <li :key="resortsKey" class="pb-2 font-light text-sm">
                          <a
                            class="text-white"
                            :href="resort.permalink"
                            :target="resort.target"
                          >
                            {{ resort.name }}
                          </a>
                        </li>
                      </template>
                    </template>
                  </ul>
                </div>
                <div class="mb-8">
                  <h4 class="text-sm font-bold tracking-widest mb-4 uppercase border-b border-white/50 pb-1.5">
                    Jamaica
                  </h4>
                  <ul>
                    <template
                      v-for="(resort, resortsKey) in alphabetizedResorts"
                    >
                      <template v-if="resort.country === 'Jamaica'">
                        <li :key="resortsKey" class="pb-2 font-light text-sm">
                          <a
                            class="text-white"
                            :href="resort.permalink"
                            :target="resort.target"
                          >
                            {{ resort.name }}
                          </a>
                        </li>
                      </template>
                    </template>
                  </ul>
                </div>
                <div class="mb-8">
                  <h4 class="text-sm font-bold tracking-widest mb-4 uppercase border-b border-white/50 pb-1.5">
                    Mexico
                  </h4>
                  <ul>
                    <template
                      v-for="(resort, resortsKey) in alphabetizedResorts"
                    >
                      <template v-if="resort.country === 'Mexico'">
                        <li :key="resortsKey" class="pb-2 font-light text-sm">
                          <a
                            class="text-white"
                            :href="resort.permalink"
                            :target="resort.target"
                          >
                            {{ resort.name }}
                          </a>
                        </li>
                      </template>
                    </template>
                  </ul>
                </div>
              </div>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem :index="1">
            <AccordionHeader show-plus-minus>
              {{ $t('our-brands') }}
            </AccordionHeader>
            <AccordionBody>
              <div>
                <div class="flex justify-center">
                  <a href="/all-inclusive-resorts" class="text-sm font-bold tracking-widest mb-4 uppercase border-b border-white/50 pb-1.5">
                    Explore Our Brands
                    <Icon name="heroicons:link-20-solid" class="inline-block w-4 h-4 ml-1" />
                  </a>
                </div>

                <div class="grid grid-cols-3 w-full">
                  <div class="flex items-center justify-center py-1 lg:py-4 px-3 lg:px-8  border-b border-r border-white/50 hover:bg-white/10">
                    <a
                      href="https://www.resortsbyhyatt.com/"
                      class="zoom-emphasize"
                      aria-label="Hyatt Ziva"
                      target="_blank"
                    >
                      <LazyLogosHyattZiva class="w-full" :fill="false" />
                    </a>
                  </div>
                  <div class="flex items-center justify-center py-1 lg:py-4 px-3 lg:px-8  border-b border-r border-white/50 hover:bg-white/10">
                    <a
                      href="https://www.resortsbyhyatt.com/"
                      class="zoom-emphasize"
                      aria-label="Hyatt Zilara"
                      target="_blank"
                    >
                      <LazyLogosHyattZilara class="w-full" :fill="false" />
                    </a>
                  </div>
                  <div class="flex items-center justify-center py-1 lg:py-4 px-3 lg:px-8  border-b border-white/50 hover:bg-white/10">
                    <a
                      href="https://www.playaresorts.com/turquoize-ziva-cancun/"
                      class="zoom-emphasize"
                      aria-label="Turquoize"
                      target="_blank"
                    >
                      <LazyLogosTurquoize class="w-full" :fill="false" />
                    </a>
                  </div>
                  <div class="flex items-center justify-center py-2 lg:py-4 px-5 lg:px-8  border-b border-r border-white/50 hover:bg-white/10">
                    <a
                      href="https://hiltonbyplaya.com/"
                      class="zoom-emphasize"
                      aria-label="Hilton"
                      target="_blank"
                    >
                      <LazyLogosHilton class="w-full" :fill="false" />
                    </a>
                  </div>
                  <div class="flex items-center justify-center py-1 lg:py-4 px-3 lg:px-8 border-b border-r border-white/50 hover:bg-white/10">
                    <a
                      href="https://sanctuarycapcana.com/"
                      class="zoom-emphasize"
                      aria-label="Sanctuary"
                      target="_blank"
                    >
                      <LazyLogosSanctuary class="w-full" :fill="false" />
                    </a>
                  </div>
                  <div class="flex items-center justify-center py-1 lg:py-4 px-3 lg:px-8 border-b border-white/50 hover:bg-white/10">
                    <a
                      href="https://kimptontresrios.com/"
                      class="zoom-emphasize py-8"
                      aria-label="Jewel Grande"
                      target="_blank"
                    >
                      <LazyLogosKimpton class="w-full text-white" :fill="false" />
                    </a>
                  </div>
                  <div class="flex items-center justify-center py-1 lg:py-4 px-3 lg:px-8 border-b border-r border-white/50 hover:bg-white/10">
                    <a
                      href="https://www.jewelgrande.com/"
                      class="zoom-emphasize"
                      aria-label="Jewel Grande"
                      target="_blank"
                    >
                      <LazyLogosJewelGrande class="w-full" :fill="false" />
                    </a>
                  </div>
                  <div class="flex items-center justify-center py-1 lg:py-4 px-3 lg:px-8 border-b border-r border-white/50 hover:bg-white/10">
                    <a
                      href="https://alltrabyplaya.com/"
                      class="zoom-emphasize"
                      aria-label="Wyndham Alltra"
                      target="_blank"
                    >
                      <LazyLogosWyndham class="w-full" :fill="false" />
                    </a>
                  </div>
                  <div class="flex items-center justify-center py-1 lg:py-4 px-3 lg:px-8 border-b border-white/50">
                    <a
                      href="https://seadustcancun.com/"
                      class="zoom-emphasize"
                      aria-label="Wyndham Alltra"
                      target="_blank"
                    >
                      <LazyLogosSeadust class="w-full" :fill="false" />
                    </a>
                  </div>
                  <div class="flex items-center justify-center py-1 lg:py-4 px-3 lg:px-8 border-r border-white/50 hover:bg-white/10">
                    <a
                      href="https://www.jewelresorts.com/"
                      class="zoom-emphasize my-6"
                      aria-label="Jewel Resorts"
                      target="_blank"
                    >
                      <LazyLogosJewel class="w-full" :fill="false" />
                    </a>
                  </div>
                  <div class="flex items-center justify-center py-1 lg:py-4 px-3 lg:px-8 border-r border-white/50 hover:bg-white/10" />
                  <div class="flex items-center justify-center py-1 lg:py-4 px-3 lg:px-8" />
                </div>
              </div>
            </AccordionBody>
          </AccordionItem>
        </Accordion>

        <template v-if="currentResort?.menu">
          <div
            v-for="(item, menuKey) in menuItemsWithoutResorstsAndBrands"
            :key="menuKey"
          >
            <NuxtLink v-if="item?.children.length === 0" :to="item.permalink" class="text-center cursor-pointer font-extralight text-lg pb-4 text-white hover:underline md:text-xl uppercase">
              {{ item.name }}
            </NuxtLink>

            <Accordion v-else class="max-w-[858px] mx-auto">
              <AccordionItem :index="1">
                <AccordionHeader show-plus-minus>
                  {{ item.name }}
                </AccordionHeader>
                <AccordionBody>
                  <div
                    v-for="(childItem, itemKey) in item.children"
                    :key="itemKey"
                    class="text-center mb-4"
                  >
                    <NuxtLink v-if="!childItem.permalink.startsWith('#')" :to="childItem.permalink" :target="childItem.target" class="text-center cursor-pointer font-extralight text-lg pb-4 text-white hover:underline md:text-xl uppercase">
                      {{ childItem.name }}
                    </NuxtLink>
                  </div>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>

          <button
            v-if="!hideBookNow.value"
            type="submit"
            class="block md:hidden px-3 lg:px-6 py-2 lg:py-3 uppercase text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed"
            :style="{
              backgroundColor: bookNowBgColor,
              color: bookNowTextColor,
            }"
            @click="onBookNowClick"
          >
            {{ $t('book-now') }}
          </button>
        </template>
      </div>
    </Container>

    <div
      v-if="showBookingWidget" class="px-6 py-4 w-full relative"
      :class="{
        'bg-black': windowIsScrolled,
      }"
    >
      <FlightBookingForm class="lg:container" />
      <button class="p-4 absolute z-100 top-1 lg:top-0 -right-1 m-4 lg:m-0 pointer-events-auto" @click="toggleBookingModal">
        <Icon name="heroicons:x-mark-20-solid" class="text-gray lg:text-white w-8 h-8" />
      </button>
    </div>
  </nav>
</template>

<style scoped>
:global(body:has(.menu-open)) {
  overflow: hidden;
}

nav {
  @apply fixed top-0 left-0 right-0 z-50 transition-all duration-300 ease-in;

  .x-container {
    @apply flex flex-col h-full;
  }

  #nav-content {
    @apply py-4 xs:px-4 md:py-5 md:px-0 xl:py-7;
    height: 80px;
  }

  &.scrolled {
    @apply bg-white shadow-xl;
  }

  &::before {
    @apply block fixed top-0 left-0 right-0 h-0 w-full bg-moonstone z-[-1];
    content: '';
  }

  &.menu-open {
    @apply bg-transparent h-screen pb-0;

    &::before {
      animation: NavHeightAnimated 0.3s ease-in forwards;
    }

    .menu {
      @apply text-white flex-grow flex flex-col space-y-4 py-12 items-center justify-start overflow-y-auto;

      /* HIDE SCROLLBARS: START */
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      /* HIDE SCROLLBARS: END */

      .accordion {
        @apply w-full px-8;
      }

      .logos {
        @apply grid grid-cols-1 gap-10 xs:grid-cols-2 md:grid-cols-3 md:gap-24;

        span {
          @apply border border-white/50 hover:bg-white/10 block px-2 py-1 font-light uppercase text-xs opacity-60 mx-auto w-max mt-4;
        }
      }
    }
  }
}

@keyframes NavHeightAnimated {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 100vh;
    opacity: 1;
  }
}
</style>
